<template>
  <div>
    <div
      id="invoice"
      style="background-color: white; min-height: 80vh"
      class="invoice-box mt-6"
    >
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="4">
            <table style="border-bottom: 1px solid #333">
              <tr>
                <td>
                  <div style="font-size: 25px; margin-bottom: 0px">INVOICE</div>
                  <br />
                  <span style="font-weight: bold">From: </span><br />
                  {{ data.hub_address.name }}<br />
                  {{ data.hub_address.location }}<br />
                </td>
                <td class="logo">
                  <v-img
                    :src="companyInfo.logo_url"
                    max-height="100px"
                    max-width="100px"
                  ></v-img>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <table>
              <tr>
                <td>
                  <span style="font-weight: bold">To: </span><br />
                  {{ data.billing_address.name }}<br />
                  {{ data.billing_address.phone_number }}<br />
                  {{ data.billing_address.address }}<br />
                  <!-- {{data.billing_address.location}}
                {{data.billing_address.area}}<br/> -->
                </td>
                <td>
                  <span style="max-width: 120px">
                    <span>INVOICE: # </span><span>{{ data.invoice_id }}</span
                    ><br />
                    Date: {{ data.ordered_date }}<br />
                    <span
                      style="
                        background-color: #ccc;
                        min-width: 100%;
                        padding: 5px;
                      "
                      >Net Amount: {{ data.price_total }}tk</span
                    ><br />
                    <span
                      style="font-size: 16px; text-align: center"
                      v-if="data.payment_method == 'COD'"
                      >CASH ON DELIVERY</span
                    >
                    <span
                      style="font-size: 20px; text-align: center"
                      v-if="data.payment_method != 'COD'"
                      >PAID</span
                    >
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table class="shrink-table">
        <tr class="heading">
          <td>Name</td>
          <td>Color</td>
          <td>Size</td>
          <td>Quantity</td>
          <td>Unit</td>
          <td>Unit Price</td>
          <td>Discount</td>
          <td>Amount</td>
        </tr>
        <tr class="item" v-for="(product, index) in products" :key="index">
          <td>
            {{ product.product_name }}
          </td>
          <td>{{ product.product_color }}</td>
          <td>{{ product.product_size }}</td>
          <td>{{ product.quantity }}</td>
          <td>{{ product.product_weight }}{{ product.product_unit }}</td>
          <td>{{ product.unit_price }}</td>
          <td>{{ product.product_discount }}</td>
          <td>{{ product.total_price }}</td>
        </tr>
      </table>

      <div class="d-flex flex-row flex-row-reverse justify-space-between">
        <div style="min-width: 200px">
          <div
            style="background-color: #ddd"
            class="my-1 px-3 d-flex flex-row justify-space-between"
          >
            <div>Subtotal:</div>
            <div>{{ data.sub_price }}tk</div>
          </div>
          <div
            style="background-color: #ddd"
            class="my-1 px-3 d-flex flex-row justify-space-between"
          >
            <div>Delivery Charge:</div>
            <div>{{ data.total_delivery_charge }}tk</div>
          </div>
          <div
            style="background-color: #ddd"
            class="my-1 px-3 d-flex flex-row justify-space-between"
          >
            <div>Overall Discount</div>
            <div>{{ data.discount }}tk</div>
          </div>
          <div
            style="background-color: #ddd"
            class="my-1 px-3 d-flex flex-row justify-space-between"
          >
            <div>VAT</div>
            <div></div>
          </div>
          <div
            style="background-color: #ddd"
            class="my-1 px-3 d-flex flex-row justify-space-between"
          >
            <div style="font-size: 18px">Grand Total:</div>
            <div style="font-size: 18px">{{ data.price_total }}</div>
          </div>
        </div>
        <div>
          <img class="paid-stamp" src="../../../assets/paid.png" />
        </div>
      </div>
    </div>
    <!-- <div class="text-center mx-3 my-3">
      <v-btn v-if="data.payment_method != 'COD'" @click="printInvoice()" small color="#495464">PRINT</v-btn>
    </div> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    delivery_details: {},
    order_id: null,
    delivery_man_id: null,
    products: [],
    companyInfo: {},
    data: {},
  }),
  methods: {
    printInvoice() {
      var prtContent = document.getElementById("invoice");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
    getCompanyInfo() {
      axios.get("site/info/").then((response) => {
        this.companyInfo = response.data.data[0];
        // console.log(this.companyInfo);
      });
    },
    getInvoiceData() {
      axios
        .get(
          "Cart/show_delivery_boy_invoice/" +
            this.order_id +
            "/" +
            this.delivery_man_id +
            "/"
        )
        .then((response) => {
          this.products = response.data.data.delivery_man_products;
          this.data = response.data.data;
          // console.log(this.data);
        });
    },
  },

  created() {
    this.order_id = this.$route.params.id;
    let user = JSON.parse(this.$store.state.user);
    this.delivery_man_id = user.user_id;
    this.getInvoiceData();
    this.getCompanyInfo();
    this.products = this.response.data.delivery_man_products;
    // console.log(this.products);
    this.delivery_details.invoice_id = this.response.data.invoice_id;
  },
};
</script>



<style scoped>
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.logo {
  float: right;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 0px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  /* border-bottom: 1px solid #333; */
  padding-bottom: 20px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

.shrink-table {
}
@media screen and (max-width: 480px) {
  .shrink-table {
    transform: scale(0.9);
    margin-left: -20px;
  }
}
@media screen and (max-width: 426px) {
  .shrink-table {
    transform: scale(0.8);
    margin-left: -30px;
  }
}
@media screen and (max-width: 390px) {
  .shrink-table {
    transform: scale(0.75);
    margin-left: -60px;
  }
}
.paid-stamp {
  margin-top: -10px;
  max-width: 190px;
}

@media only screen and (max-width: 600px) {
  .paid-stamp {
    max-width: 140px;
  }
  .invoice-box {
    padding: 10px;
  }
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}
.rtl table {
  text-align: right;
}
.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>